import { Component } from 'react';

class Test extends Component {
  render() {
    return (
        <div className="tesy">testdeeddd32defefd-qqqq</div>
    );
  }
}

export default Test;
